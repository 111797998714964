import React from "react"

import Playlist from "../components/playlist"

import imgbanner from "../images/index/thumb/beats.jpg"
import allcategories from "../content/categorytitles.json"
import videolist from "../content/playlistbeats.json"


const BeatsPage = ({location}) => (
  <Playlist
    location={location}
    headingClassName={"color-text-secondary"}
    path={"beats"}
    bannerimg={imgbanner}
    title={allcategories.beats.title}
    writeup={allcategories.beats.description} 
    videolist={videolist} />
)

export default BeatsPage
